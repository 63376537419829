import {reactive, ref} from 'vue';

var TITLE = document.title, csrf = '', gatekeeperUrl = '';

function toast(text, type, duration) {
  window.dispatchEvent(new CustomEvent('toast', {detail:{text, type, duration}}));
}

function api(action, data, cb, errCb, always) {
  if (typeof data === 'function' || !data) {
    errCb = cb;
    cb = data;
    data = {};
  }
  return api.request('/api/' + action, data, (err, json) => {
    if (err || json.error) {
      console.error(err || json.error);
      if (typeof errCb === 'function') {
        errCb(err || json.error);
      } else if (json?.error === 'AUTH_REQUIRED' || json?.error === 'CSRF_CHECK_FAILED') {
        toast('Die Sitzung ist abgelaufen, bitte Seite neu laden!', 'error');
      } else {
        toast((err ? err.message : json.error), 'error');
      }
    } else if (typeof cb === 'function') {
      cb(json.data);
    }
    if (always) {
      always(err, json);
    }
  });
}

api.__proto__ = reactive({user:null});

api.request = (url, data, cb) => {
  var body, req, headers = {
    'X-CSRF':csrf
  };
  if (data instanceof FormData) {
    body = data;
  } else {
    body = JSON.stringify(data);
    headers['Content-Type'] = 'application/json';
  }
  req = fetch(url, {
    method:'POST',
    headers,
    body
  }).then(res => res.json())
    .then(json => cb(null, json))
    .catch(cb)
    .finally(() => req.loading.value = false);
  req.loading = ref(true);
  return req;
};

api.init = cb => {
  api('init', res => {
    csrf = res.csrf;
    gatekeeperUrl = res.gatekeeper;
    api.nounce = res.nounce;
    api.setUser(res.user || null);
    cb(res);
  });
};

api.setUser = user => {
  api.__proto__.user = user;
};

function checkRoles(roles, method) {
  var userRoles;
  if (!api.user) {
    return false;
  }
  userRoles = api.user.roles || api.user.role;
  if (!userRoles) {
    return false;
  }
  if (!(roles instanceof Array)) {
    roles = [roles];
  }
  if (!(userRoles instanceof Array)) {
    userRoles = [userRoles];
  }
  return userRoles[method](role => roles.includes(role));
}

api.hasRole = {
  any:roles => checkRoles(roles, 'some'),
  all:roles => checkRoles(roles, 'every'),
  none:roles => !checkRoles(roles, 'every')
};

api.hasRoles = api.hasRole;

api.logout = (cb) => api('logout', () => {
  api.__proto__.user = null;
  if (typeof cb === 'function') {
    cb();
  } else {
    window.location.href = gatekeeperUrl;
  }
});

api.copyToClipboard = (text, showMsg) => {
  var copyEl = document.createElement('textarea');
  copyEl.value = text;
  copyEl.style.opacity = 0;
  copyEl.style.position = 'absolute';
  document.body.prepend(copyEl);
  copyEl.select();
  document.execCommand('copy');
  copyEl.remove();
  if (showMsg) {
    toast((showMsg || 'Kopiert!'), 'success');
  }
};

api.setTitle = title => {
  document.title = (title ? title + ' - ' : '') + TITLE;
};

function guardRouter(router) {
  api.router = router;
  router.beforeEach(to => {
    var path;
    if (api.user && localStorage.afterLogin) {
      path = {path:localStorage.afterLogin};
      localStorage.afterLogin = '';
      return path;
    } else if (api.user || to.meta.public) {
      api.setTitle(to.meta.title);
      return true;
    } else {
      localStorage.afterLogin = to.fullPath;
      window.location.href = gatekeeperUrl + 'login/host/' + window.location.host;
    }
  });
}

function routeFocusMain(router) {
  api.router = router;
  router.afterEach(() => {
    var main = document.querySelector('main');
    main.tabIndex = 0;
    main.focus();
    main.removeAttribute('tabIndex');
  });
}

function setupRouter(router) {
  api.router = router;
  guardRouter(router);
  routeFocusMain(router);
}

export default {
  install:app => app.api = app.config.globalProperties.api = api,
  init:api.init,
  guardRouter,
  routeFocusMain,
  setupRouter
};