<template lang="pug">
v-app-bar(app elevation="1")
  template(v-slot:prepend)
    v-btn(v-if="!hideMenu" icon @click="toggleMenu()" aria-label="Hauptmenü anzeigen"): .menuIcon(:class="{menuOpen:showClose}")
  template(v-slot:append)
    v-btn#accountIcon(icon aria-label="Benutzer-Menü anzeigen" v-if="api.user")
      v-avatar(:image="image" color="blue-lighten-4")
        span.text-h6(aria-hidden="true") {{(api.user?.username || '').substr(0, 2).toUpperCase()}}
  v-app-bar-title
    PitLogo
    | {{title}}

v-menu(activator="#accountIcon" transition="slide-y-transition" offset="8px")
  v-list.accountMenu(role="list")
    div(v-for="(itm, idx) in accountMenu")
      .text-overline(:class="{menuOpen}" v-if="typeof itm==='string'") {{itm}}
      v-list-item(v-else :prepend-icon="this.ICON[itm.icon]" :title="itm.title" :value="'item'+idx" :to="itm.to" @click="itm.click?.()" role="listitem")

v-navigation-drawer(v-if="!hideMenu" app v-model="menuMobileOpen" :rail="!menuOpen" aria-label="Hauptmenü")
  v-list(role="list")
    div(v-for="(itm, idx) in mainMenu")
      .text-overline(:class="{menuOpen}" v-if="typeof itm==='string'") {{itm}}
      v-list-item(v-else-if="itm.component"): component(:is="itm.component" :menuOpen="menuOpen")
      v-list-item(v-else :prepend-icon="this.ICON[itm.icon]" :title="itm.title" :value="'item'+idx" :to="itm.to" @click="itm.click?.()" role="listitem")
        template(v-if="itm.badge && itm.badge.text" v-slot:append): v-badge(:color="itm.badge.color" :content="itm.badge.text" inline)
</template>

<script>
import {useDisplay} from 'vuetify';

export default {
  props:['title', 'hideMenu', 'mainMenu', 'accountMenu'],
  data() {
    return {
      menuOpen:true,
      menuMobileOpen:false,
      mobile:useDisplay().mobile
    };
  },
  computed:{
    showClose() {
      return ((!this.mobile && this.menuOpen) || (this.mobile && this.menuMobileOpen));
    },
    image() {
      var image;
      if (this.api.user?.image) {
        image = 'data:image/png;base64,' + this.api.user.image;
      } else if (this.api.user?.jarvisID) {
        image = 'https://static.pit.at/user/' + this.api.user.jarvisID + '.jpg';
      }
      return image;
    }
  },
  created() {
    if (!this.mobile) {
      this.menuMobileOpen = true;
    }
  },
  methods:{
    toggleMenu() {
      var menu = (this.mobile ? 'menuMobileOpen' : 'menuOpen');
      this[menu] = !this[menu];
    }
  }
};
</script>

<style lang="stylus">
.v-app-bar
  .menuIcon, .menuIcon:before, .menuIcon:after
    display:block
    width:calc(var(--v-btn-height) * .6)
    height:2px
    position:relative
    border-radius:10%
    transition:top .2s .2s, transform .2s, background-color .2s
  .menuIcon.menuOpen
    background-color:rgba(0,0,0,0)
  .menuIcon:before, .menuIcon:after
    content:''
    position:absolute
    transform:rotate(0)
  .menuIcon:before
    top:calc(var(--v-btn-height) * .2)
  .menuIcon:after
    top:calc(var(--v-btn-height) * -.2)
  .menuOpen:before, .menuOpen:after
    transition:top .2s, transform .2s .2s, background-color 0s .2s
    top:0%
  .menuOpen:before
    transform:rotate(45deg)
  .menuOpen:after
    transform:rotate(-45deg)
@css {
  .v-app-bar .menuIcon, .v-app-bar .menuIcon:before, .v-app-bar .menuIcon:after {
    background-color:rgba(var(--v-theme-on-background), 1);
  }
}
.v-app-bar-title
  text-align:center
  .pit-logo
    margin-right:10px
.v-navigation-drawer, .v-list
  .v-list-item__prepend > .v-icon ~ .v-list-item__spacer
      width:16px
    .text-overline
      position:relative
      transition:margin-top .25s, opacity .2s
      margin:-1.5rem 0 -.1rem 1rem
      opacity:0
      z-index:-1
    .text-overline.menuOpen
      margin-top:.4rem
      opacity:1
    .v-list
      padding-top:4px
  .accountMenu
    .v-list-item__prepend > .v-icon ~ .v-list-item__spacer
      width:16px
    .text-overline
      margin:.2rem 1rem 0 1rem
    .v-list-item-title
      margin-right:2rem
</style>