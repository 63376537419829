<template lang="pug">
#modals
  v-dialog(
    v-for="modal in ui.modals"
    v-model="modal.show"
    width="auto"
    min-width="350"
    :activator="modal.activator"
    :class="{isMobile}"
    @update:modelValue="update(modal, $event)"
  )
    v-card.pa-2: form(@submit.prevent="submit(modal)")
      v-card-title.text-h5(v-if="modal.title") {{modal.title}}
      v-card-text(v-if="modal.text") {{modal.text}}
      component(v-if="modal.component" :is="modal.component" :modal="modal")
      v-card-actions
        v-spacer
        v-btn(
          v-for="btn in modal.buttons"
          :type="btn.submit ? 'submit' : 'button'"
          :variant="btn.type ? 'tonal' : 'text'"
          :color="(btn.type || '').split(' ')[0]"
          :class="{focus:(btn.type || '').includes('focus')}"
          @click="click(modal, btn)"
        ) {{btn.text}}

#toasts
  transition-group(name="toast")
    v-card.toast(v-for="toast in ui.toasts" :key="toast" :color="toast.type" :text="toast.text")
</template>

<script>
import {useDisplay} from 'vuetify';

export default {
  data() {
    return {
      isMobile:false
    };
  },
  methods:{
    update(modal, open) {
      if (!open) {
        modal.close();
      }
    },
    submit(modal) {
      if (modal.submit) {
        modal.submit(modal);
        modal.show = false;
        modal.close();
      }
    },
    click(modal, button) {
      if (typeof button.click === 'function') {
        button.click();
      }
      if (!button.keep && !button.submit) {
        modal.show = false;
        modal.close();
      }
    }
  },
  mounted() {
    this.isMobile = useDisplay().mobile;
  }
};
</script>

<style lang="stylus">
.v-dialog.isMobile
  align-items:flex-start
  padding-top:45px

#toasts
  position:fixed
  width:100%
  z-index:9000
  height:0
  top:4.5rem
  text-align:center
  &>.toast
    display:table
    margin:0 auto .3rem
  .toast>.v-card-text
    padding:.6rem 1rem
  .toast-enter-active
    animation:toast .8s
  .toast-leave-active
    animation:toast .6s reverse

@keyframes toast
  0%
    opacity:0
    margin-top:-2rem
  30%
    opacity:0

.v-container>h1:first-child
  margin-top:-8px
</style>