<template lang="pug">
v-btn.pitify-fab(@click="click()" icon size="large" :disabled="disabled" :color="color")
  v-icon(:icon="icon")
  v-tooltip(v-if="tooltip" activator="parent" location="start" v-model="showTip")
    | {{tooltip}}
</template>

<script>
export default {
  props:{
    icon:{},
    disabled:{default:false},
    tooltip:{default:''},
    color:{default:'#009fe3'}
  },
  data() {
    return {
      showTip:false
    };
  },
  methods:{
    click() {
      this.showTip = false;
      this.$emit('click');
    }
  }
};
</script>

<style lang="stylus">
.pitify-fab
  position:fixed
  right:.75rem
  bottom:.75rem
  color:white
  transition:margin-right .3s, opacity .3s
  &[disabled]
    margin-right:calc(var(--v-btn-height) * -1 - 2rem)
    opacity:0
  & + .pitify-fab
    bottom:calc((var(--v-btn-height) + 12px) + 1.25rem)
  & + .pitify-fab + .pitify-fab
    bottom:calc((var(--v-btn-height) + 12px) * 2 + 1.75rem)
</style>