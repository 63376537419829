<template lang="pug">
v-app
  PitifyNavigation(:hideMenu="hideMenu" :title="title" :mainMenu="mainMenu" :accountMenu="accountMenu")

  v-main(app)
    router-view

  PitifyUI
</template>

<script>
export default {
  props:['hideMenu', 'title', 'mainMenu', 'accountMenu']
};
</script>