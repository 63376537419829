import {createVuetify} from 'vuetify';
import {aliases, mdi} from 'vuetify/iconsets/mdi-svg';
import PitifyApp from '../components/PitifyApp.vue';
import PitifyNavigation from '../components/PitifyNavigation.vue';
import PitLogo from '../components/PitLogo.vue';
import icons from '../../src/icons';
import './polyfill';

import 'vuetify/styles';

export default {
  install(app) {
    var vuetify = createVuetify({
      theme:{
        defaultTheme:(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
        cspNonce:app.api.nounce
      },
      icons:{
        defaultSet:'mdi',
        aliases,
        sets:{
          mdi
        }
      },
      defaults:{
        global:{
          hideDetails:'auto'
        },
        VAvatar:{
          cover:true
        }
      }
    });
    app.config.globalProperties.ICON = icons;
    app.component('PitifyApp', PitifyApp);
    app.component('PitifyNavigation', PitifyNavigation);
    app.component('PitLogo', PitLogo);
    vuetify.install(app);
  }
};