import {h, ref, reactive, nextTick, shallowRef} from 'vue';
import {VCardText, VTextField} from 'vuetify/components';
import uiComponent from '../components/PitifyUI.vue';
import fabComponent from '../components/PitifyFAB.vue';

var ui = reactive({
  toasts:[],
  modals:[],
  toast(text, type, duration) {
    var toast;
    if (typeof type === 'number') {
      duration = type;
      type = null;
    }
    type = (type || 'success');
    toast = {text, type, duration, show:true};
    ui.toasts.unshift(toast);
    setTimeout(() => ui.toasts.splice(ui.toasts.indexOf(toast), 1), (duration || 2500) + 100);
  },
  error(text, duration) {
    this.toast(text, 'error', (duration || 3500));
  },
  modal(modal) {
    var restoreFocus = document.querySelector(':focus');
    modal.show = true;
    if (modal.component) {
      modal.component = shallowRef(modal.component);
    }
    if (modal.$event) {
      modal.activator = modal.$event.target;
    }
    modal.close = () => {
      setTimeout(() => {
        ui.modals.splice(ui.modals.indexOf(modal), 1);
        nextTick(() => {
          if (restoreFocus) {
            restoreFocus.focus();
          }
        });
      }, 300);
    };
    ui.modals.push(modal);
    nextTick(() => {
      var el = document.querySelector('.v-overlay--active'),
        focusEL = (el.querySelector('.focus') || el.querySelector('button, input, select, textarea'));
      if (focusEL) {
        focusEL.focus();
      }
    });
    return modal;
  },
  alert(title, text, $event) {
    if (typeof text !== 'string') {
      $event = text;
      text = title;
      title = '';
    }
    ui.modal({title, text, buttons:[{text:'OK', type:'success'}], $event});
  },
  confirm(modal, cb) {
    var {title, text, buttons, $event} = modal;
    if (typeof modal === 'string') {
      text = modal;
    }
    buttons = (buttons || [{text:'Abbrechen'}, {text:'OK', type:'warning focus', click:cb}]);
    ui.modal({title, text, buttons, $event});
  },
  prompt(modal, cb) {
    var {title, text, value, buttons, width, label, placeholder, required, $event} = modal;
    if (typeof modal === 'string') {
      text = modal;
    }
    value = ref(value || '');
    buttons = (buttons || [{text:'Abbrechen'}, {text:'OK', type:'success', submit:true}]);
    modal = ui.modal({
      title,
      submit() {
        cb(value.value);
      },
      component:{
        render() {
          return h('div', [
            h(VCardText, {
              style:{paddingTop:(title ? '.25rem' : undefined)}
            }, () => [
              text,
              h(VTextField, {
                label,
                placeholder,
                required,
                variant:'solo',
                hideDetails:'auto',
                'model-value':value,
                style:{width:(width || '100%'), marginTop:(text ? '.5rem' : 0)}
              })
            ])
          ]);
        }
      },
      buttons,
      $event
    });
  }
});

export default {
  install:app => {
    app.component('PitifyUI', uiComponent);
    app.component('PitifyFAB', fabComponent);
    app.config.globalProperties.ui = ui;
    window.addEventListener('toast', ev => {
      var {text, type, duration} = ev.detail;
      ui.toast(text, type, duration);
    });
  }
};